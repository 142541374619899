<template>
  <div class="sectionOneBoxWrap">
    <v-row class="insideItemBoxWrap">
      <div
        v-if="isLoading"
        class="dashboard-slider-tags"
      >
        <v-skeleton-loader
          v-for="n in 7"
          :key="n"
          type="card"
          class="ma-2"
          width="200px"
          height="150px"
        />
      </div>
      <div
        v-else
        class="dashboard-slider-tags"
      >
        <!--<div
          v-if="isScrollLeft"
          class="scroll-btn scroll-left"
          @click="scrollLeft"
        >
          <v-icon> mdi-chevron-left </v-icon>
        </div>-->
        <div class="content-wrapper">
          <!-- :loading="isLoading" -->
          <div class="draggable-wrapper">
            <v-card
              v-for="(row, i) in items"
              :key="i"
              class="Tag_card"
            >
              <div class="itemBoxes defaultbg">
                <div class="iconBox">
                  <span>
                    <v-icon v-if="row?.icon"> {{ row.icon }}</v-icon>
                    <Icons
                      v-else-if="row?.myIcon"
                      :name="row.myIcon"
                    />
                    <v-icon v-else> mdi-help</v-icon>
                  </span>
                </div>
                <div class="textBox">
                  <p>{{ row?.name ? row.name.replace(/_/g, " ") : "--" }}</p>
                  <div class="si-unit">
                    <span v-if="row?.unit">{{ row.unit }}</span>
                  </div>
                </div>
                <div class="valueBox">
                  {{ row.value }}
                </div>
              </div>
            </v-card>
          </div>
        </div>
        <!--<div
          class="scroll-btn scroll-right"
          @click="scrollRight"
        >
          <v-icon> mdi-chevron-right </v-icon>
        </div>-->
      </div>
    </v-row>
  </div>
</template>
<script>
export default {
  components: {
    Icons: () => import("@/components/base/icons.vue"),
  },
  props: {
    isLoading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      isScrollLeft: false,
    };
  },
  methods: {
    scrollLeft() {
      document
        .getElementsByClassName("content-wrapper")[0]
        .scrollTo({ left: 0, behavior: "smooth" });
      this.isScrollLeft = false;
    },
    scrollRight() {
      const t = document.getElementsByClassName("content-wrapper")[0];
      // const w = t.offsetWidth;
      const sl = t.scrollLeft + 250;
      t.scrollTo({ left: sl, behavior: "smooth" });
      this.isScrollLeft = true;
    },
  },
};
</script>
<style lang="sass" scoped>
.dashboard-slider-tags
  position: relative
  margin: 1rem 0rem
  user-select: none !important
  display: flex
  justify-content: space-between
  .scroll-btn
    position: absolute
    z-index: 1
    height: 100%
    cursor: pointer
    flex-basis: 30px
    display: flex
    justify-content: center
    align-items: center
    &:hover
      background: #e5e2e2 !important
      opacity: 50%

  .scroll-left
    left: 0
  .scroll-right
    right: 0
.dashboard-slider-tags
  display: flex
  .scroll-btn
    cursor: pointer
    flex-basis: 30px
    display: flex
    justify-content: center
    align-items: center
    i
      font-size: 2.375rem
      color: rgba(34,34,34,0.25)
  &:hover
    i
      color: rgba(34,34,34,0.95)
  .content-wrapper
    overflow-x:  auto
    gap: 0
    display: unset
    width: 100%

    .draggable-wrapper
      display: flex
      gap: 0rem
    .Tag_card
      width: 14% !important
      margin: 0 1% 0 0
      cursor: pointer
      transition: 0.7s all ease
      flex: 0 0 16.6666666667%
      max-width: 16.6666666667%
      // padding: 12px
      border-radius: 0
      background: transparent
      box-shadow: 0 0 0 0 !important
      border-color: transparent
      &:last-child
        margin: 0 0 0 0

.insideItemBoxWrap .dashboard-slider-tags .content-wrapper .Tag_card
  min-width: unset !important
  flex: unset !important
  max-width: unset !important
.si-unit
  height: 13px
@media (max-width: 480px)
  .dashboard-slider-tags .content-wrapper .draggable-wrapper
    flex-wrap: wrap
  .dashboard-slider-tags .content-wrapper .Tag_card
    width: 48% !important
    margin: 0 1% 1% 0
    &:last-child
      margin: 0 0 1% 0
    &:nth-child(even)
      margin: 0 0 1% 0
</style>
<style scoped>
.content-wrapper::-webkit-scrollbar {
  display: none !important;
  /* width: 12px; */
}

/* .content-wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.content-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
} */

</style>
